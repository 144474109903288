import { EventData } from 'components/EventsCalendar/types';
import bounceApiClient from '../common/utils/bounceApiClient';

import {
  AssociationDto,
  GetExternalContactsCountDto,
  GroupDto,
  GroupMemberRole,
  GroupsDto,
  GroupMembersDto,
  AssociationMembersDto,
  AssociationMemberRole,
  AssociationMemberStatus,
  AssociationRequestsToPublishDto,
  AlbumDto,
  AlbumsDto,
  AssociationEventLogDto,
  AssociationEventLogSortBy,
  AssociationPublishRequestSortBy,
  AssociationAnalyticsSummaryDto,
  GroupAnalyticsSummaryDto,
  AnalyticsRevenueAllTimeDto
} from './types';
import { GenericSuccessResponse } from 'legacy/common/types/response';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';

export async function createGroup(group): Promise<GroupDto> {
  const { data } = await bounceApiClient.post('/api/groups', group);

  return data;
}
export async function createGroupAlbum(groupId, album): Promise<AlbumDto> {
  const { data } = await bounceApiClient.post(
    `/api/groups/${groupId}/albums`,
    album
  );

  return data;
}

export async function updateGroupAlbum(
  groupId,
  albumId,
  album
): Promise<AlbumDto> {
  const { data } = await bounceApiClient.put(
    `/api/groups/${groupId}/albums/${albumId}`,
    album
  );
  return data;
}

export async function deleteGroupAlbum(groupId, albumId): Promise<AlbumDto> {
  const { data } = await bounceApiClient.delete(
    `/api/groups/${groupId}/albums/${albumId}`
  );
  return data;
}

export async function updateGroup({ group, groupId }): Promise<GroupDto> {
  const { data } = await bounceApiClient.put(`/api/groups/${groupId}`, group);

  return data;
}

export async function updateAssociation({
  association,
  associationId
}): Promise<AssociationDto> {
  const { data } = await bounceApiClient.patch(
    `/api/associations/${associationId}`,
    association
  );

  return data;
}

export async function confirmStudentEmail({ email, associationId }): Promise<{
  associationId: string;
  email: string;
}> {
  const { data } = await bounceApiClient.post(
    `/api/associations/${associationId}/members/startVerification`,
    {
      email
    }
  );

  return data;
}

export async function confirmEmailCode({
  associationId,
  code
}: {
  associationId: string;
  code: string;
}) {
  const { data } = await bounceApiClient.put(
    `/api/associations/${associationId}/members/verify`,
    {
      verificationCode: code
    }
  );

  return data;
}

export async function getGroups({
  role
}: {
  role?: GroupMemberRole[];
}): Promise<GroupsDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/groups`,
    params: {
      role: role.toString(),
      limit: 1000
    }
  });

  return data;
}
export async function getGroupAlbums({
  groupId,
  pageNumber,
  limit
}: {
  groupId: string;
  pageNumber: number;
  limit: number;
}): Promise<AlbumsDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/v2/groups/${groupId}/albums`,
    params: {
      pageNumber,
      limit
    }
  });

  return data;
}
export async function getGroupAlbumMoments({
  groupId,
  albumId,
  pageNumber,
  limit
}: {
  groupId: string;
  albumId: string;
  pageNumber: number;
  limit: number;
}): Promise<AlbumsDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/groups/${groupId}/albums/${albumId}/moments`,
    params: {
      pageNumber,
      limit
    }
  });

  return data;
}
export async function getGroupAlbum({
  groupId,
  albumId
}: {
  groupId: string;
  albumId: string;
}): Promise<AlbumDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/groups/${groupId}/albums/${albumId}`
  });

  return data;
}

export async function listGroups({
  search,
  pageNumber
}: {
  search?: string;
  pageNumber: number;
}): Promise<GroupsDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/groups`,
    params: {
      limit: 20,
      search,
      pageNumber
    }
  });

  return data;
}

export async function listAssociations(): Promise<AssociationDto[]> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/associations`
  });

  return data;
}

export async function listOwnAssociations(): Promise<AssociationDto[]> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/associations/me`
  });

  return data;
}

export async function getGroup({
  groupId
}: {
  groupId: string;
}): Promise<GroupDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/groups/${groupId}`
  });

  return data;
}

export async function getUnauthorizedGroup({
  groupId
}: {
  groupId: string;
}): Promise<GroupDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/service/groups/${groupId}`
  });

  return data;
}

export async function getAssociation({
  associationId
}: {
  associationId: string;
}): Promise<AssociationDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/associations/${associationId}`
  });

  return data;
}

export async function getUnauthorizedAssociation({
  associationId
}: {
  associationId: string;
}): Promise<AssociationDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/service/associations/${associationId}`
  });
  return data;
}

export async function getAssociationMembers({
  associationId,
  query,
  cursor
}: {
  associationId: string;
  query: string;
  cursor: string;
}): Promise<AssociationMembersDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/associations/${associationId}/members`,
    params: {
      limit: 20,
      query,
      cursor
    }
  });

  return data;
}

export async function getAssociationAdmins({
  associationId,
  query,
  cursor
}: {
  associationId: string;
  query: string;
  cursor: string;
}): Promise<AssociationMembersDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/associations/${associationId}/admins`,
    params: {
      limit: 20,
      query,
      cursor
    }
  });

  return data;
}

export async function getAssociationMemberRequests({
  associationId,
  query,
  cursor
}: {
  associationId: string;
  query: string;
  cursor: string;
}): Promise<AssociationMembersDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/associations/${associationId}/members/pending`,
    params: {
      limit: 20,
      query,
      cursor
    }
  });

  return data;
}

export async function getAssociationAffiliatedMembers({
  associationId,
  query,
  cursor
}: {
  associationId: string;
  query: string;
  cursor: string;
}): Promise<AssociationMembersDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/associations/${associationId}/affiliatedMembers`,
    params: {
      limit: 20,
      query,
      cursor
    }
  });

  return data;
}

export async function getAssociationRequestsToPublish({
  associationId,
  cursor,
  numberOfItems,
  sort,
  order
}: {
  associationId: string;
  cursor: string;
  numberOfItems?: number;
  sort?: AssociationPublishRequestSortBy;
  order?: 'asc' | 'desc';
}): Promise<AssociationRequestsToPublishDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/associations/${associationId}/events/publishRequests`,
    params: {
      limit: numberOfItems || 20,
      cursor,
      sort: sort || 'submissionDate',
      order: order || 'desc'
    }
  });

  return data;
}

export async function getAssociationEventsLog({
  associationId,
  cursor,
  numberOfItems,
  order,
  sort
}: {
  associationId: string;
  cursor: string;
  numberOfItems?: number;
  sort?: AssociationEventLogSortBy;
  order?: 'asc' | 'desc';
}): Promise<AssociationEventLogDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/associations/${associationId}/events/log`,
    params: {
      limit: numberOfItems || 20,
      cursor,
      order: order || 'desc',
      sort: sort || 'eventDate'
    }
  });

  return data;
}

export async function updateAssociationMemberStatus({
  associationId,
  userId,
  role,
  status
}: {
  associationId: string;
  userId: string;
  role?: AssociationMemberRole;
  status?: AssociationMemberStatus;
}) {
  const { data } = await bounceApiClient.put(
    `/api/associations/${associationId}/members/${userId}`,
    {
      role,
      status
    }
  );

  return data;
}

export async function removeAssociationMember({
  associationId,
  userId
}: {
  associationId: string;
  userId: string;
}) {
  const { data } = await bounceApiClient.delete(
    `/api/associations/${associationId}/members/${userId}`
  );

  return data;
}

export async function getGroupEvents({
  groupId,
  pageNumber,
  limit,
  afterStartDate,
  beforeStartDate,
  includeDrafts
}: {
  groupId: string;
  pageNumber?: string;
  limit?: string;
  afterStartDate?: string;
  beforeStartDate?: string;
  includeDrafts?: boolean;
}): Promise<EventData> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/groups/${groupId}/events`,
    params: {
      ...(pageNumber && { pageNumber }),
      ...(limit && { limit }),
      ...(afterStartDate && { afterStartDate }),
      ...(beforeStartDate && { beforeStartDate }),
      ...(includeDrafts && { includeDrafts })
    }
  });
  return data;
}
export async function getGroupMembers({
  pageNumber,
  limit,
  groupId,
  role,
  query
}: {
  groupId: string;
  pageNumber?: number;
  limit?: number;
  role: string;
  query?: string;
}): Promise<GroupMembersDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/groupMembers/${groupId}`,
    params: {
      ...(pageNumber && { pageNumber }),
      ...(limit && { limit }),
      role,
      query
    }
  });
  return data;
}
export async function getPendingGroupMembers({
  pageNumber,
  limit,
  groupId,
  query
}: {
  groupId: string;
  pageNumber?: number;
  limit?: number;
  query?: string;
}): Promise<GroupMembersDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/groupMembers/${groupId}/pending`,
    params: {
      ...(pageNumber && { pageNumber }),
      ...(limit && { limit }),
      query
    }
  });
  return data;
}

export async function approveMemberRequest({
  groupId,
  userId
}: {
  groupId: string;
  userId: string;
}): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient.put(`/api/groupMembers/approve`, {
    groupId,
    userId
  });

  return data;
}

export async function rejectMemberRequest({
  groupId,
  userId
}: {
  groupId: string;
  userId: string;
}): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient.put(`/api/groupMembers/reject`, {
    groupId,
    userId
  });

  return data;
}

export async function removeMember({
  groupId,
  userId
}: {
  groupId: string;
  userId: string;
}): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient({
    method: 'delete',
    url: '/api/groupMembers',
    data: {
      groupId,
      userId
    }
  });
  return data;
}
export async function changeMemberRole({
  groupId,
  userId,
  role
}: {
  groupId: string;
  userId: string;
  role: 'admin' | 'member';
}): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient({
    method: 'put',
    url: '/api/groupMembers/role',
    data: {
      groupId,
      userId,
      role
    }
  });
  return data;
}
export async function requestMembership({
  groupId
}: {
  groupId: string;
}): Promise<{ success: boolean }> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: '/api/groupMembers/request',
    data: {
      groupId
    }
  });

  return data;
}

export async function requestAssociationMembership({
  associationId
}: {
  associationId: string;
}): Promise<{ success: boolean }> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: `/api/associations/${associationId}/members/join`,
    data: {
      associationId
    }
  });

  return data;
}

export async function uploadExternalContacts({
  groupId,
  contacts
}: {
  groupId: string;
  contacts: {
    phoneNumber: string;
    firstName?: string;
    lastName?: string;
    email?: string;
  }[];
}): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient.put(
    `/api/groups/${groupId}/externalContacts`,
    { contacts }
  );

  return data;
}

export async function getExternalContactsCount(
  groupId: string
): Promise<GetExternalContactsCountDto> {
  const { data } = await bounceApiClient.get(
    `/api/groups/${groupId}/externalContacts/count`
  );

  return data;
}

export async function messageExternalContacts({
  groupId,
  message
}: {
  groupId: string;
  message: string;
}): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient.post(
    `/api/groups/${groupId}/externalContacts/message`,
    { message }
  );

  return data;
}

export async function getAssociationAnalyticsSummary({
  associationId
}: {
  associationId: string;
}): Promise<AssociationAnalyticsSummaryDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/associations/${associationId}/analytics/summary`
  });

  return data;
}

export async function getAssociationAnalyticsRevenueAllTime({
  associationId
}: {
  associationId: string;
}): Promise<AnalyticsRevenueAllTimeDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/associations/${associationId}/analytics/revenue/all-time`,
    params: {
      timezone
    }
  });

  return data;
}

export async function getGroupAnalyticsSummary({
  groupId
}: {
  groupId: string;
}): Promise<GroupAnalyticsSummaryDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/groups/${groupId}/analytics/summary`
  });

  return data;
}

export async function getGroupAnalyticsRevenueAllTime({
  groupId
}: {
  groupId: string;
}): Promise<AnalyticsRevenueAllTimeDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/associations/${groupId}/analytics/revenue/all-time`,
    params: {
      timezone
    }
  });

  return data;
}
